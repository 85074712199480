<template>
  <div id="Introduction">
    <header>
      <div>
        <img src="../../assets/image/findOut/background.jpg" />
      </div>
      <div>
        <img v-if="personalDetail.headimgurl" :src=" Global.changeImg(personalDetail.headimgurl)" />
        <img v-else  src="../../assets/image/home/image1.png" />

        <p class="first">{{personalDetail.member_info ? personalDetail.member_info.name : personalDetail.name}}</p>
        <p class="second">
          <span v-if="member_info.job">{{member_info.job}}</span>
          <van-icon v-if="member_info.city" color="#727387"  name="location" style="margin:0 0.05rem 0 0;font-size:0.12rem"/>
          <span class="address">{{member_info.city}}</span></p>
        <p class="third"><span v-for="(j,items) in personalDetail.tags" :key="items">{{j.name}}</span></p>
        <div class="forth">
          <!-- <span><span>{{personalDetail.quiz_count}}</span>回答</span> -->
          <span><span>{{personalDetail.wts_log_count}}</span>人打过招呼</span>
        </div>
        <p class="fifth" v-if="personalDetail.isContactShow == 1">
          <img src="../../assets/image/findOut/wechat.png" />
          <span>{{member_info.wechat}}</span>
        </p>
        <p class="fifth" v-else-if="personalDetail.wtslog.length && personalDetail.wtslog[0].status == 1">
          <img src="../../assets/image/findOut/wechat.png" />
          <span>{{member_info.wechat}}</span>
        </p>
      </div>
    </header>
    <div class="bottom">
      <van-tabs @click="onClick"  @disabled="onClickDisabled">
        <van-tab title="个人信息">
          <div class="personalInfo">
            <div >
              <p class="second">个人简介</p>
              <p class="third">{{member_info.intro ?member_info.intro : '这个人很懒什么也没留下~'}}</p>
            </div>
            <div >
              <p class="second">可提供资源</p>
              <p class="third">{{member_info.supply}}</p>
            </div>
            <div >
              <p class="second">想要获得资源</p>
              <p class="third">{{member_info.explore}}</p>
            </div>
            <div >
              <p class="second">个人领域标签</p>
              <div class="titleTab">
                <div v-if="member_info.industry == 1">保险</div>
                <div v-if="member_info.industry == 2">创业</div>
                <div v-if="member_info.industry == 3">互联网</div>
                <div v-if="member_info.industry == 4">观察者</div>
                <div v-if="member_info.industry == 5">投资</div>
                <div v-if="member_info.industry == 6">媒体</div>
              </div>
            </div>
          </div>
        </van-tab>
        <!-- <van-tab   title="TA的回答">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            class="hotMain"
          >
            <div @click="toDetail(i.id)" v-for="(i,item) in getDocList" :key="item">
              <p class="second">{{i.question}}</p>
              <div class="first"><div><img :src="personalDetail.headimgurl ? Global.changeImg(personalDetail.headimgurl) :'../assets/image/home/image1.png'" /><span>{{i.member_info.name}}</span>回答了</div></div>
              <p class="third"><img src="../../assets/image/home/answer.png" />{{i.answer}}</p>
              <div class="forth">
                <div><img v-for="(j,itemj) in i.circusee" :key="itemj" :src="Global.changeImg(j.head_img.headimgurl)" />等{{i.circusee_count}}人已围观</div>
                <div>
                  <span>{{Global.fromatTimestamp(i.answer_at)}}</span>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab> -->
      </van-tabs>
    </div>

   <div class="lineK" @click="toLine">
      <p>联系</p>
      <p>客服</p>
    </div>
    <div class="addMore" @click="toBack">
      返回
    </div>
    <div class="botFixed">
      <!-- <span @click="toQuestion">向TA提问</span> -->
      <span class="last" v-if="personalDetail.wtslog.length == 0" @click="toHello(id)"><van-icon name="chat" style="margin-right:6px;font-size:0.14rem" />打个招呼</span>
    </div>
    <van-popup v-model="show" style="background:none">
      <img class="main" src="img/kf2.png" />
      <div class="close">
        <span @click="show = false">
          <van-icon color="#fff" name="cross" style="font-size:0.2rem" />
        </span>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: 'Introduction',
   data(){
    return{
      show:false,
      id:'',
      personalDetail:'',
      member_info:'',
      finished:false,
      loading:true,
      next_page_url:'',
      getDocList:[],//公司列表
    }
  },
  mounted(){
    this.id = this.$route.query.id
    // 增加大咖浏览量
    this.axios({
      method: 'PATCH',
      url: '/master/addMasterViews/'+this.id,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    })
    // 大咖详情
    this.axios({
      method: 'GET',
      url: '/master/masterDetail?masterId='+this.id,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.personalDetail = res.data.data
        this.member_info = res.data.data.member_info
      }
    })  
    this.axios({
      method: 'GET',
      url: '/master/masterQuiz?masterId='+this.id,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.getDocList = res.data.data.data;
        this.loading = false;
      }
    })

  },
  methods:{
    toLine(){
      this.show = true
    },
    toBack(){
      this.$router.go(-1)
    },
     onLoad(){
      if(this.next_page_url){
        this.axios({
          method: 'GET',
          url:this.next_page_url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    onClickDisabled(){
      this.$toast('暂未开放');
    },
    onClick(name, title) {
      console.log(name,title)
    },
    // 打招呼
    toHello(id){
      this.axios({
        method: 'POST',
        url: '/recognition/greetMaster',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          masterId:id
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$toast(res.data.data.msg)          
        }
      })
    },
    toQuestion(){
      console.log(this.personalDetail)
      if(this.personalDetail.member_info.name){
        this.$router.push({
          path: '/Question?id='+this.personalDetail.id+'&name='+this.personalDetail.member_info.name,
        })
      }else{
        this.$router.push({
          path: '/Question?id='+this.personalDetail.id+'&name='+this.personalDetail.name,
        })
      }
     
    },
    toDetail(id){
      this.$router.push({
        path: '/QuestionDetail?id='+id,
      })
    },
  }
}
</script>
<style lang="less" scoped>
#Introduction{
  .main{
    width: 2.63rem;
  }
  .close{
    text-align: center;
    >span{
      width: 0.35rem;
      height: 0.35rem;
      background: #6e6f72;
      border-radius: 50%;
      margin-top: 0.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 0.2rem;
    }
  }
  .lineK{
    position: fixed;
    bottom: 1.4rem;
    width: .46rem;
    height: .46rem;
    background: linear-gradient(345deg,#3890ff,#1b78ee);
    box-shadow: 0 0 5px rgba(9,104,223,.39);
    border-radius: 50%;
    right: .15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F6F7FD;
    flex-direction: column;
    >p{
      font-size: 0.13rem;
    }
  }
  .addMore{
    position: fixed;
    bottom: .8rem;
    width: .46rem;
    height: .46rem;
    background: linear-gradient(345deg,#3890ff,#1b78ee);
    box-shadow: 0 0 5px rgba(9,104,223,.39);
    border-radius: 50%;
    right: .15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F6F7FD;
  }
  .botFixed{
    position: fixed;
    bottom: 0.19rem;
    width: 100%;
    display: flex;
    justify-content: center;
    >span{
      width: 1.47rem;
      height: 0.42rem;
      text-align: center;
      line-height: 0.42rem;
      font-size: 0.14rem;
      border-radius: 0.05rem;
      background: #ECEDF7;
      color: #3890FF;
    }
    >span.last{
      background: #3890FF;
      color: #F6F7FD;
      // margin-left: 0.15rem;
    }
  }
  .titleTab{
    display: flex;
    flex-wrap: wrap;
    padding: 0.15rem 0;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-bottom: 0.1rem;
      margin-right: 0.12rem;
    }
  }
  .personalInfo{
    padding: 0 0.15rem;
    >div{
      padding-top: 0.15rem;
      .third{
        color: #989AB1;
        font-size: 0.13rem;
        line-height: 0.17rem;
        padding: 0.15rem 0;
        border-bottom: .1px solid #5C5D6E61;
      }
      .second{
        color: #F6F7FD;
        font-size: 0.14rem;
      }
    }
  }
  .bottom{
    background: #23252E;
    margin-top: 0.1rem;
    padding-bottom: 0.62rem;
  }
  .hotMain{
    padding: 0 0.15rem;
    >div{
      padding: 0.18rem 0;
      .forth{
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
          }
        }
        >div:first-child{
          color: #989AB1;
          display: flex;
          align-items: center;
          font-size: 0.12rem;
          >img{
            width: 0.14rem;
            border-radius: 50%;
          }
          >img:nth-child(n+2){
            margin-left: -0.03rem;
          }
          >img:last-child{
            margin-right: 0.05rem;
          }
        }
      }
     
      .third{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #989AB1;
        font-size: 0.14rem;
        line-height: 0.17rem;
        margin-bottom: 0.15rem;
        >img{
          width: 0.14rem;
          display: inline-block;
          line-height: 0.17rem;
          margin-bottom: -0.02rem;
        }
      }
      .second{
        color: #F6F7FD;
        font-size: 0.14rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .first{
        margin: 0.11rem 0 0.12rem;
        >div{
          color: #727387;
          font-size: 0.12rem;
          display: flex;
          align-items: center;
          >span{
            margin: 0 0.1rem 0 0.08rem;
          }
          >img{
            width: 0.19rem;
            border-radius: 50%;
          }
        }
        >span{
          color: #727387;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  header{
    background: #23252E;
    >div:first-child{
      overflow: hidden;
      >img{
        width: 100%;
        position: relative;
      }
    }
    >div:last-child{
      padding: 0.2rem 0 0.15rem 0.34rem;
      position: relative;
      >img{
        position: absolute;
        width: 0.7rem;
        height: 0.7rem;
        right: 0.3rem;
        top: -0.2rem;
        border: 2px solid #23252E;
        border-radius: 50%;
      }
      .fifth{
        display: flex;
        margin-top: 0.05rem;
        align-items: center;
        >img{
          width: 0.2rem;
          margin-right: 0.1rem;
        }
        >span{
          font-size: 0.13rem;
          color: #989AB1;
        }
      }
      .first{
        font-size: 0.16rem;
        color: #F6F7FD;
      }
      .forth{
        margin-top: 0.12rem;
        display: flex;
        >span{
          margin-right: 0.08rem;
          color: #727387;
          display: flex;
          align-items: center;
          font-size: 0.12rem;
          >span{
            color: #F6F7FD;
            font-size: 0.12rem;
            margin-right: 0.05rem;
          }
        }
      }
      .third{
        display: flex;
        flex-wrap: wrap;
        >span{
          padding: 0 0.05rem;
          color: #75B2FF;
          font-size: 0.12rem;
          border-radius: 0.02rem;
          background: #2862AC;
          margin: 0 0.05rem 0 0;
        }
      }
      .second{
        margin: 0.08rem 0;
        font-size: 0.14rem;
        display: flex;
        color: #F6F7FD;
        align-items: center;
        >span:first-child{
          margin-right: 0.1rem;
        }
        >.address{
          font-size: 0.13rem;
          display: flex;
          color: #989AB1;
        }
      }
    }
  }
}
</style>